// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'https://webservice.infitec.es/restgestionwa',
  // baseUrl: 'https://restgestionwa.infitec.es',
  baseUrl: 'https://restgestionwapruebas.infitec.es',
  // baseUrl:'http://kirtley.ruta-ip.info/restgestionwa',
  // baseUrl:'http://localhost:22584',
  rolAdmin:'1',
  rolCliente:'6',
  estadoPasarelaPendiente:'PAS/PEN',
  estadoPasarelaOk:'PAS/OK',
  estadoPasarelaError:'PAS/ERROR',
  // productoId:'35146'

  //SubTipoPedidoVenta
  pagoTarjeta:'P/PASARELA-ENVIADO',
  pagoTienda:'P/LOCAL-INICIADO',
  pagoEnviado:'P/ENVIADO',

  //formasEntrega
  recogerTienda:'R',
  envioDomicilio:'D',

  //formasPago
  local:1,
  tarjeta:2,
  transferencia:3,

  //formas de pedido
  pedidoStandard:0,
  pedidoEscrito:1,
  pedidoMixto:2
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
